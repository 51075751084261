import React from "react";
import "css/divider.css";

const TextDivider = ({ text, hidden }) => {

  if (!hidden) {
    return null;
  }

  return (
    <div
      className="hrdivider sub-menu-category-sidebar mb-0"
      style={{
        padding: "0 2px"
      }}
    >
      <span>{text}</span>
      <hr style={{ margin: 0 }} />
    </div>
  );
};

export default TextDivider;
