import Cookies from "js-cookie"
import { CountryData } from "./countryData"

export class LocalCookies {
  static cookie = {
    accessibleCountry: 'accessible-country',
    token: 'token',
    filterCountry: 'filter-country'
  }

  static filterCountry = {
    getDefault: () => {
      const data = Cookies.get(this.cookie.accessibleCountry)
      if (!data) return CountryData.default
      return data.split(',')
    },
    // getDefault: () => {
    //   const accessible = this.filterCountry.getAccessible()
    //   const fromCookies = this.filterCountry.get()
    //   if (fromCookies) return fromCookies
    //   if (accessible) return accessible
    //   return CountryData.default
    // },
    setDefault: (data) => {
      Cookies.set(this.cookie.accessibleCountry, (data ?? []).join(','))
    },
    deleteDefault: () => {
      Cookies.remove(this.cookie.accessibleCountry)
    },
    get: () => {
      const data = Cookies.get(this.cookie.filterCountry)
      if (!data) return null
      return data.split(',')
    },
    set: (data) => {
      Cookies.set(this.cookie.filterCountry, (data ?? []).join(','))
      // Cookies.set(this.cookie.filterCountry, [...(this.filterCountry.get() ?? []), data].join(','))
    },
    delete: () => { Cookies.remove(this.cookie.filterCountry) },
    header: () => {
      let data = this.filterCountry.get() ?? []
      if (data.includes(CountryData.country.global) || data.length === 0) return {}
      return {
        "Country-Filter": data
      }

      // let countries = this.filterCountry.get() ?? []
      // countries = countries.filter(country => ![CountryData.country.global, ''].includes(country))

      // if (!countries.length) return {}
      // return { "Country-Filter": countries }
    },
    navbar: (value) => {
      const data = value ?? this.filterCountry.get() ?? []
      const selected = data[data.length - 1]
      if (data.includes(CountryData.country.global) && selected !== CountryData.country.global) return data.filter((value) => value !== CountryData.country.global)
      if (selected === CountryData.country.global) return [CountryData.country.global]
      if ((data ?? '') == '') return [this.filterCountry.getDefault()[0] == '' ? CountryData.country.global : this.filterCountry.getDefault()[0]]
      else
        return data
    }
  }

}