export const DashboardAuthorize = {
  dashboard: { name: "Dashboard", value: "dashboard:view" },
  dashboardExport: { name: "Dashboard Export", value: "dashboard:export" }
};

export const UserManagementAuthorize = {
  curated: { name: "Curated", value: "curated:view" },
  message: { name: "Message", value: "message:view" },
  notes: { name: "Notes", value: "message:notes" },
  account: { name: "Account", value: "account:view" },
  accountExport: { name: "Account Export", value: "account:export" },
  profile: { name: "Profile", value: "profile:view" },
  profileExport: { name: "Profile Export", value: "profile:export" },
  changeBirthdateRequest: { name: "Change Birthdate Request", value: "request.birthdate:view" },
  badgeVerification: { name: "Badge Verification", value: "admin.identity:view" },
  allBadgeVerification: { name: "All Badge Verification", value: "admin.identity:edit" },
  kyc: { name: "KYC", value: "kyc:view" },
  allDataKyc: { name: "All Data KYC", value: "admin.kyc:edit" },
  referralProfile: { name: "Referral Profile", value: "admin.refferal:view" },
  profileTag: { name: "Profile Tag", value: "profile:tag" },
  billionaireKeywordSettings: { name: "Billionaire Keyword Settings", value: "admin.keywords.billionaire:view" },
  categorySelectionYouHost: { name: "Category Selection YouHost", value: "admin.youhost.category:view" }
};

export const ContentManagementAuthorize = {
  servicesExperienceList: { name: "Services/Experience List", value: "service:view" },
  servicesExperienceListExport: { name: "Services/Experience List Export", value: "service:export" },
  groupList: { name: "Group List", value: "admin.group:view" },
  groupListExport: { name: "Group List Export", value: "admin.groups:export" },
  featureGroup: { name: "Feature Group", value: "admin.featured.group:view" },
  featurePlaces: { name: "Feature Places", value: "admin.featured.places:view" },
  featureExperience: { name: "Feature Experience", value: "admin.featured.services:view" },
  featureEvent: { name: "Feature Event", value: "admin.featured.event:view" },
  featureCommunities: { name: "Feature Communities", value: "admin.featured.community:view" },
  events: { name: "Events", value: "admin.event:view" },
  eventExport: { name: "Events", value: "admin.event:export" },
  eventAttendees: { name: "Event Attendees", value: "admin.event.attendees:view" },
  eventTicket: { name: "Event Ticket", value: "admin.event.ticket:view" },
  places: { name: "Places", value: "admin.place:view" },
  placeExport: { name: "Place Export", value: "admin.place:export" },
  wishlistUser: { name: "Wishlist User", value: "admin.wishlist.user:view" },
  wishlistUserExport: { name: "Wishlist User Export", value: "admin.wishlist.user:export" },
  wishlistExp: { name: "Wishlist Exp", value: "admin.wishlist.exp:view" },
  wishlistExpExport: { name: "Wishlist Exp Export", value: "admin.wishlist.exp:export" },
  wishlistEvent: { name: "Wishlist Event", value: "admin.wishlist.event:view" },
  wishlistEventExport: { name: "Wishlist Event Export", value: "admin.wishlist.event:export" },
  wishlistGroup: { name: "Wishlist Group", value: "admin.wishlist.group:view" },
  wishlistGroupExport: { name: "Wishlist Group Export", value: "admin.wishlist.group:export" },
  wishlistPlace: { name: "Wishlist Place", value: "admin.wishlist.place:view" },
  wishlistPlaceExport: { name: "Wishlist Place Export", value: "admin.wishlist.place:export" },
  wishlistBought: { name: "Wishlist Bought", value: "admin.wishlist.bought:view" },
  wishlistBoughtExport: { name: "Wishlist Bought Export", value: "admin.wishlist.bought:export" },
  discoverExperience: { name: "Discover Experience", value: "admin.discover:view" },
  utmHistory: { name: "UTM History", value: "admin.utm.history:view" },
  utmHistoryExport: { name: "UTM History Export", value: "admin.utm.history:export" },
  utmSignup: { name: "UTM Signup", value: "admin.utm.signup:view" },
  utmSignupExport: { name: "UTM Signup Export", value: "admin.utm.signup:export" },
  categorySelectionEvents: { name: "Category Selection Events", value: "admin.event.category:view" },
  categorySelectionExperience: { name: "Category Selection Experience", value: "admin.product.category:view" },
  community: { name: "Community", value: "admin.community:view" },
  communityExport: { name: "Community Export", value: "admin.community:export" },
  contentAllCommunities: { name: "Content of All Communities", value: "admin.content.communities:view" },
  contentAllProfilePost: { name: "Content of all Profile Post", value: "admin.content.post:view" },
  article: { name: "Article", value: "admin.article:view" },
  articleExport: { name: "Article Export", value: "admin.article:export" },
  categorySelectionPlaces: { name: "Category Selection Places", value: "admin.category.selection.places:view" },
  articleCategory: { name: "Article Category", value: "admin.article.category:view" },
  reportedContent: { name: "Reported Content", value: "admin.reported.content:view" },
  communityContents: { name: "Community Contents", value: "admin.community.contents:view" },
  miniEvent: { name: "Meet Up", value: "admin.minievent:view" },
  miniEventExport: { name: "Meet Up Export", value: "admin.minievent:export" },
  miniEventDispute: { name: "Meet Up Dispute", value: "admin.minievent.dispute:view" },
  miniEventDisputeExport: { name: "Meet Up Dispute Export", value: "admin.minievent.dispute:export" },
};

export const MatchContentManagementAuthorize = {
  matchedPeople: { name: "Matched People", value: "matched.people:view" },
};

export const MeetsContentManagementAuthorize = {
  meetHostsOverview: { name: "Meet Hosts", value: "meet.hosts.overview:view" },
  meetList: { name: "Meet List", value: "meet.list:view" },
  meetDetail: { name: "Meet Detail", value: "meet.detail:view" },
};

export const FinanceManagementAuthorize = {
  paymentRequest: { name: "Payment Request", value: "admin.payment.request:view" },
  withdrawalRequest: { name: "Withdrawal Request", value: "admin.withdrawal.request:view" },
  transaction: { name: "Transaction", value: "admin.transaction:view" },
  transactionExport: { name: "Transaction Export", value: "admin.transaction:export" },
  debitCreditAssets: { name: "Debit Credit Assets", value: "admin.debit.credit.assets:view" },
  assetHoldingLive: { name: "Asset Holding Live", value: "admin.asset.holding.live:view" },
  assetHoldingDaily: { name: "Asset Holding Daily", value: "admin.asset.holding.daily:view" },
  viewAllFeesEarned: { name: "View all Fees Earned", value: "admin.youapp.fees.earnings.all:view" },
  totalFeesEarnedPerDay: { name: "Total Fees Earned Per Day", value: "admin.youapp.fees.earnings.daily:view" },
  lockedAssets: { name: "Locked Assets", value: "admin.loacked.assets:view" },
  youPlusSubscriptionUser: { name: "You + Subscription User", value: "admin.subscription.user:view" },
  youPlusSubscriptionUserExport: { name: "You + Subscription User Export", value: "subscription.user:export" }
};

export const SelfDiscoveryAuthorize = {
  horoscopeDaily: { name: "Horoscope Daily", value: "admin.horoscope.daily:view" },
  zodiacDaily: { name: "Zodiac Daily", value: "admin.zodiac.daily:view" },
  horoscopeDailyTitle: { name: "Horoscope Daily Title", value: "admin.horoscope.daily.title:view" },
  baziBone: { name: "Bazi Bone", value: "admin.bazi.bone:view" },
  baziBoneTitle: { name: "Bazi Bone Title", value: "admin.bazi.bone.title:view" },
  luckCategory: { name: "Luck Category", value: "admin.luck.category:view" },
  luckItem: { name: "Luck Item", value: "admin.luck.item:view" },
  consultationsCategories: { name: "Consultation Categories", value: "admin.consultationsCategories:view" },
  cetaphysicsPracticesCategory: { name: "Metaphysic Practices Categories", value: "admin.metaphysicsPracticesCategory:view" },
  cetaphysicistsProfile: { name: "Metaphysicist Profiles", value: "admin.metaphysicistsProfile:view" }
};

export const MasterAdminAuthorize = {
  role: { name: "Role", value: "role:view" },
  admin: { name: "Admin", value: "admin:view" }
};

export const GlobalSettingAuthorize = {
  globalFeeSetting: { name: "Global Fee Setting", value: "admin.fee.setting:view" },
  subscriptionSetting: { name: "Subscription Setting", value: "admin.subscription.setting:view" },
  offensiveWords: { name: "Offensive Words", value: "admin.offensive.words:view" },
  exemptionFees: { name: "Exemption Fees", value: "admin.skip.fee:view" },
  dailyFxRatesSnapshoot: { name: "Daily FX Rates Snapshoot", value: "admin.rates.snapshoot:view" },
  allExchangeRates: { name: "All Exchange Rates", value: "admin.exchange:view" },
  appBanner: { name: "App Banner", value: "admin.appbanner:view" },
  anncouncement: { name: "Anncouncement", value: "admin.anncouncement:view" },
  promoCode: { name: "Promo Code", value: "admin.promo.code:view" },
  versionSetting: { name: "Version Setting", value: "admin.version.setting:view" },
  keywordsSetting: { name: "Keywords Setting", value: "keywords.setting:view" }
};

export const LogsAuthorize = {
  log: { name: "Logs", value: 'logs:view' }
}