import { jwtDecode } from "jwt-decode";
import { detectHost } from "./api"
import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';

/**
 * 
 * @typedef {'dev' | 'uat' | 'prod' | 'local' | ''} EnvType
 * 
 * @author Cah Bagoes
 * 
 */
export class Socket {
    static urls = {
        local: 'http://localhost:3001',
        dev: "https://api-dev.youapp.ai",
        uat: "https://api-chats-uat.youapp.ai", // https://api-uat.youapp.ai/xchats
        preProd: "https://testflight.youapp.ai:3002/",
        prod: "https://api-release33216789.youapp.ai:3001"
    }

    static emails = {
        local: 'curateddating@admin.com',
        dev: "curateddating@admin.com",
        uat: "curateddating@admin.com",
        preProd: "curateddating@admin.com",
        prod: "curateddating@admin.com"
    }

    /**
     * @returns {string}
     */
    static get detectHost() { return detectHost({ getCurrentEnv: true }) }

    static get getUrl() { return Socket.urls[Socket.detectHost] }

    /**
     * @returns {EnvType} 
     */
    static get getToken() {
        // return Socket.tokens[Socket.detectHost] 

        const result = process.env[`REACT_APP_TOKEN_${Socket.detectHost.toUpperCase()}`]
        // console.log('env', Socket.detectHost.toUpperCase(), result);

        return result
    }

    static get getEmail() { return Socket.emails[Socket.detectHost] }

    static get decodedToken() {
        const result = jwtDecode(Socket.getToken)
        return result
    }

    static useToken(queryToken) {

        const isDefault = (queryToken ?? '') === ''

        let decoded
        try {
            if (!isDefault)
                decoded = jwtDecode(queryToken)
            else
                decoded = this.decodedToken
        } catch (error) {
            alert('the token is invalid')
            decoded = this.decodedToken
        }

        return {
            token: isDefault ? this.getToken : queryToken,
            isDefault,
            decodedToken: decoded
        }
    }


    /**
     * Custom hook to handle socket.io connection and messaging in a React component.
     * 
     * @author cah bagoes
     * @param {Object} opt - The URL of the socket.io server.
     * @param {string} opt.serverUrl - The URL of the socket.io server.
     * @param {string} opt.token - The URL of the socket.io server.
     * @param {boolean} opt.reconnection - The URL of the socket.io server.
     * @returns {{
     *   socket: Socket | null,
     *   isConnected: boolean,
     *   addListener: function(string, function): void,
     *   removeListener: function(string, function): void,
     *   emitEvent: function(string, any): void
     * }} - An object containing the socket instance, connection status, and functions to add/remove listeners and emit events.
     */
    static useSocket = ({ serverUrl, token, reconnection }) => {
        const [socket, setSocket] = useState(null);  // Store socket instance
        const [isConnected, setIsConnected] = useState(false);  // Track connection status

        useEffect(() => {
            // Initialize socket connection
            // console.log('socket ', Socket.getUrl);

            console.log(`used token ${JSON.stringify(jwtDecode(token)?.profileId)}`);

            const socketIo = io(serverUrl ?? Socket.getUrl, {
                reconnection,
                extraHeaders: {
                    Authorization: `Bearer ${token ?? Socket.getToken}`
                }
            });

            // Save the socket instance in state
            setSocket(socketIo);

            // Listen for the 'connect' event
            socketIo.on('connect', () => {
                setIsConnected(true);
                console.log('Connected to server');
            });

            // Handle socket disconnection
            socketIo.on('disconnect', () => {
                setIsConnected(false);
                console.log('Disconnected from server');
            });

            // Clean up the socket connection when the component unmounts
            return () => {
                socketIo.disconnect();
            };
        }, [serverUrl]);

        /**
         * Function to add a listener for a specific socket event.
         * 
         * @param {string} event - The event name to listen for.
         * @param {function} callback - The function to call when the event is received.
         */
        const addListener = (event, callback) => {
            if (socket) {
                socket.on(event, callback);  // Listen to the event
            }
        };

        /**
         * Function to remove a listener for a specific socket event.
         * 
         * @param {string} event - The event name to stop listening for.
         * @param {function} callback - The function to remove from the event.
         */
        const removeListener = (event, callback) => {
            if (socket) {
                socket.off(event, callback);  // Remove listener from the event
            }
        };

        /**
         * Function to emit a socket event.
         * 
         * @param {string} event - The event name to emit.
         * @param {any} data - The data to send with the event.
         */
        const emitEvent = (event, data) => {
            if (socket) {
                socket.emit(event, data);  // Emit the event with data
            }
        };

        return {
            socket,         // The socket instance
            isConnected,    // Connection status (true if connected)
            addListener,    // Function to add a listener for an event
            removeListener, // Function to remove a listener for an event
            emitEvent,      // Function to emit an event with data
        };
    };

}