import { Form } from '@themesberg/react-bootstrap';
import { Select } from 'antd';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { LocalCookies } from '../data/localCookies';
import { useNavigate } from "react-router-dom";
import { CountryData } from '../data/countryData';
import { useLocation } from 'react-router-dom';

export const  CountryNavBar = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const [show, setShow] = useState(true)
  const [state, setState] = useState(LocalCookies.filterCountry.get());

  function matchRoute(path, objectPath) {
    let regexPath = path
      .replace(':id', '[a-zA-Z0-9]+')
      .replace(':lang', '[a-zA-Z0-9]+');
    let regex = new RegExp('^' + regexPath + '$'); 
    return regex.test(objectPath);
  }

  useLayoutEffect(() => {
    const currPath = location.pathname
    const check = CountryData.exceptionPagePath().find((route) => matchRoute(route, currPath))
    if (check) setShow(false)
    else setShow(true)

    // CountryData.exceptionPagePath().includes(currPath) ? setShow(false) : setShow(true)
  }, [location])

  useEffect(() => {
    LocalCookies.filterCountry.set(state)
  }, [state])

  return (
    <>
      {
        show && (
          <Form className='pb-2'>
            <Form.Group id="country">
              <Select
                mode="multiple"
                maxTagCount={'responsive'}
                placeholder="Filter countries"
                value={LocalCookies.filterCountry.navbar(state)}
                allowClear
                options={LocalCookies.filterCountry.getDefault().map((value) => ({
                  value: value,
                  label: value,
                }))}
                onBlur={() => navigate(0)}
                onChange={(value) => {
                  const [first, ...rest] = value
                  if (first === CountryData.country.global) setState(rest)
                  else setState(value)
                }}
                style={{ width: "200px" }
                }
              />
            </Form.Group>
          </Form>
        )
      }
    </>
  );
};
