import React, { createContext, useContext, useRef, useEffect, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const RequestManagerContext = createContext();

export const useRequestManager = () => useContext(RequestManagerContext);

export const RequestManagerProvider = ({ children }) => {
    const location = useLocation();
    const cancelTokenSource = useRef(axios.CancelToken.source());

    const registerRequest = useCallback(() => {
        return cancelTokenSource.current.token;
    }, []);

    const cancelAllRequests = useCallback(() => {
        cancelTokenSource.current.cancel('Operation canceled due to new page navigation.');
        cancelTokenSource.current = axios.CancelToken.source()
    }, []);

    useEffect(() => {
        cancelAllRequests();
    }, [location, cancelAllRequests]);

    const value = useMemo(() => ({
        registerRequest,
        cancelAllRequests
    }), [registerRequest, cancelAllRequests]);

    return (
        <RequestManagerContext.Provider value={value}>
            {children}
        </RequestManagerContext.Provider>
    );
};
