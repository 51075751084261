import { route, routeList } from "refactor_routes"

export class CountryData {
  static country = {
    global: 'Global',
    malaysia: 'Malaysia',
    singapore: 'Singapore',
    thailand: 'Thailand',
    indonesia: 'Indonesia',
  }

  static exceptionPage = () => routeList().reduce((prev, curr) => {
    if (curr.hideCountryNavbar === true) {
      prev = [...prev, curr]
    }
    return prev
  }, [])

  static exceptionPagePath = () => CountryData.exceptionPage().map((value) => {
    if (value.path[0] !== '/') return `/${value.path}`
    return value.path
  })

  static default = Object.values(this.country)

  static noGlobal = Object.values(this.country).filter((value) => value !== this.country.global)

  static globalHandler = (data) => {
    if ((data ?? []).includes(this.country.global)) return []
    return data ?? []
  }
}

export const showNotInPage = [
  "/dashboard/overview",
  "/logHistory/overview",
  "/globalSettings/overview",
  "/versioningSettings/overview",
  "/versioningSettings/overview",
  "/offensiveWords/overview",
  "/dailyfx/overview",
  "/exchangeRate/overview",
  "/subcriptions",
  "/matching-setting/overview",
  "/keywordSettings/overview",
  "/appbanner/overview",
  "/promocode/overview",
  "/announcement/overview",
  "/announcementAlert/overview",
  "/profileExemptionBuyerFees/overview",
  "/newsletter/setting",
  "/newsletter/sentlog/overview",
  "/newsletter/overview",
  "/user/overview",
  "/role/overview",
  "/luckitems/overview",
  "/luckycategories/overview",
  "/bazititles/overview",
  "/bazidaily/overview",
  "/horoscopetitle/overview",
  "/horoscope/overview",
  "/zodiac/overview",
  "/subcription"
]
