import { menuPage } from "refactor_routes";
// import { useAuth } from "data/context/auth";

export const MenuPage = (autorizes, email) => {
  const isSuperAdmin = email !== "superadmin@youapp.ai";

  return menuPage(isSuperAdmin, autorizes);

  // return [
  //   {
  //     category: "Dashboard",
  //     title: "Dashboard",
  //     path: Routes.DashboardOverview.path,
  //     auth: "dashboard:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("dashboard:view"))
  //   },
  //   // {
  //   //   category: "Dashboard",
  //   //   title: "Dashboard Stats",
  //   //   path: Routes.StatisticDashboard.path,
  //   //   auth: "admin.dashboard:stats",
  //   // hidden: (!/user?.email === "superadmin@youapp.ai" && autorizes?.includes("admin.dashboard:stats"))
  //   // },
  //   {
  //     category: "USER MANAGEMENT",
  //     title: "Accounts",
  //     path: Routes.AccountOverview.path,
  //     auth: "account:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("account:view"))
  //   },
  //   // {
  //   //   category: "USER MANAGEMENT",
  //   //   title: "All Accounts",
  //   //   path: Routes.AllAccountOverview.path,
  //   // //   auth: "all.account:view",
  //   // sidebar: true,//
  //   // hidden: (!isSuperAdmin || autorizes?.includes("all.account:view"))
  //   // },
  //   {
  //     category: "USER MANAGEMENT",
  //     title: "Profiles",
  //     path: Routes.ProfileOverview.path,
  //     auth: "profile:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("profile:view"))
  //   },
  //   // {
  //   //   category: "USER MANAGEMENT",
  //   //   title: "All Profiles",
  //   //   path: Routes.AllProfileOverview.path,
  //   //   auth: "profile.all:view",
  //   // sidebar: true,//
  //   // hidden: (!isSuperAdmin || autorizes?.includes("profile.all:view"))
  //   // },
  //   {
  //     category: "USER MANAGEMENT",
  //     title: "Change Birthdate Request",
  //     path: Routes.ChangeBirthDateOverview.path,
  //     auth: "request.birthdate:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("request.birthdate:view"))
  //   },
  //   {
  //     category: "USER MANAGEMENT",
  //     title: "Update Profile Request",
  //     path: Routes.ProfileChangeRequestOverview.path,
  //     auth: "request.birthdate:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("request.birthdate:view"))
  //   },
  //   // {
  //   //   category: "USER MANAGEMENT",
  //   //   title: "Reported Profile",
  //   //   path: Routes.ReportedProfileOverview.path,
  //   //   auth: "reports:view",
  //   // hidden: (!/user?.email === "superadmin@youapp.ai" && autorizes?.includes("reports:view"))
  //   // },
  //   {
  //     category: "USER MANAGEMENT",
  //     title: "Badge Verification",
  //     path: Routes.IdentityVerificationOverview.path,
  //     auth: "admin.identity:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.identity:view"))
  //   },
  //   {
  //     category: "USER MANAGEMENT",
  //     title: "All Data Badge Verification",
  //     path: Routes.EditIdentityVerificationOverview.path,
  //     auth: "admin.identity:edit",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.identity:edit"))
  //   },
  //   {
  //     category: "USER MANAGEMENT",
  //     title: "KYC",
  //     path: Routes.KYCOverview.path,
  //     auth: "kyc:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("kyc:view"))
  //   },
  //   {
  //     category: "USER MANAGEMENT",
  //     title: "All Data KYC",
  //     path: Routes.KYCEdit.path,
  //     auth: "admin.kyc:edit",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.kyc:edit"))
  //   },
  //   {
  //     category: "USER MANAGEMENT",
  //     title: "Referral Profile List",
  //     path: Routes.ReferralList.path,
  //     auth: "admin.refferal:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.refferal:view"))
  //   },
  //   {
  //     category: "USER MANAGEMENT",
  //     title: "Profile Tag",
  //     path: Routes.ProfileTag.path,
  //     auth: "profile:tag",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("profile:tag"))
  //   },
  //   {
  //     category: "USER MANAGEMENT",
  //     title: "Billionaire Keyword Settings",
  //     path: Routes.BillionaireKeywordSettings.path,
  //     auth: "admin.keywords.billionaire:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.keywords.billionaire:view"))
  //   },
  //   {
  //     category: "CONTENT MANAGEMENT",
  //     title: "UTM Campaign History",
  //     path: Routes.UtmHistory.path,
  //     auth: "admin.utm.history:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.utm.history:view"))
  //   },
  //   {
  //     category: "CONTENT MANAGEMENT",
  //     title: "UTM Signups",
  //     path: Routes.UtmHistorySignUp.path,
  //     auth: "admin.utm.signup:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.utm.signup:view"))
  //   },
  //   {
  //     category: "REPORT MANAGEMENT",
  //     title: "Reported Contents",
  //     path: Routes.ReportedContent.path,
  //     auth: "admin.reported.content:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.reported.content:view"))
  //   },

  //   {
  //     category: "USER MANAGEMENT",
  //     title: "Category Selection YOUHosts",
  //     path: Routes.CategorySelectionYouHost.path,
  //     auth: "admin.youhost.category:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.youhost.category:view"))
  //   },
  //   {
  //     category: "SERVICES CONTENT MANAGEMENT",
  //     title: "Services/Experiences",
  //     path: Routes.ProductServicesOverview.path,
  //     auth: "service:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("service:view"))
  //   },
  //   // {
  //   //   category: "SERVICES CONTENT MANAGEMENT",
  //   //   title: "Reported Services / Experiences",
  //   //   path: Routes.ReportedServiceOverview.path,
  //   //   auth: "admin.reported.service:view",
  //   // hidden: (!/user?.email === "superadmin@youapp.ai" && autorizes?.includes("admin.reported.service:view"))
  //   // },
  //   {
  //     category: "SERVICES CONTENT MANAGEMENT",
  //     title: "Featured Experiences",
  //     path: Routes.FeatureServiceOverview.path,
  //     auth: "admin.featured.services:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.featured.services:view"))
  //   },
  //   {
  //     category: "SERVICES CONTENT MANAGEMENT",
  //     title: "Discover Experience",
  //     path: Routes.Discoveroverview.path,
  //     auth: "admin.discover:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.discover:view"))
  //   },
  //   {
  //     category: "SERVICES CONTENT MANAGEMENT",
  //     title: "Discover Submissions",
  //     path: Routes.ViewSubmissionsOverview.path,
  //     auth: "admin.discover:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.discover:view"))
  //   },
  //   {
  //     category: "SERVICES CONTENT MANAGEMENT",
  //     title: "Featured Discover Experiences",
  //     path: Routes.FeatureDiscoverExpOverview.path,
  //     auth: "admin.featured.services:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.featured.services:view"))
  //   },
  //   {
  //     category: "SERVICES CONTENT MANAGEMENT",
  //     title: "Category Selection Experience",
  //     path: Routes.CategorySelectionExperience.path,
  //     auth: "admin.product.category:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.product.category:view"))
  //   },
  //   {
  //     category: "MATCHING CONTENT MANAGEMENT",
  //     title: "Matched People",
  //     path: Routes.MatchedPeople.path,
  //     auth: "matched.people:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("matched.people:view"))
  //   },
  //   {
  //     category: "MINI EVENTS CONTENT MANAGEMENT",
  //     title: "Mini Events",
  //     path: Routes.MiniEventOverview.path,
  //     auth: "matched.people:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("matched.people:view"))
  //   },
  //   {
  //     category: "MINI EVENTS CONTENT MANAGEMENT",
  //     title: "Mini Event Disputes",
  //     path: Routes.MiniEventDispute.path,
  //     auth: "matched.people:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("matched.people:view"))
  //   },
  //   {
  //     category: "EVENTS CONTENT MANAGEMENT",
  //     title: "Featured Events",
  //     path: Routes.FeatureEventOverview.path,
  //     auth: "admin.featured.event:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.featured.event:view"))
  //   },
  //   {
  //     category: "EVENTS CONTENT MANAGEMENT",
  //     title: "Event",
  //     path: Routes.EventListOverview.path,
  //     auth: "admin.event:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.event:view"))
  //   },
  //   {
  //     category: "EVENTS CONTENT MANAGEMENT",
  //     title: "Event Attendees",
  //     path: Routes.EventAttendeesOverview.path,
  //     auth: "admin.event.attendees:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.event.attendees:view"))
  //   },
  //   // {
  //   //   category: "EVENTS CONTENT MANAGEMENT",
  //   //   title: "Reported Event",
  //   //   path: Routes.ReportedEventListOverview.path,
  //   //   auth: "admin.reported.event:view",
  //   // hidden: (!/user?.email === "superadmin@youapp.ai" && autorizes?.includes("admin.reported.event:view"))
  //   // },
  //   {
  //     category: "EVENTS CONTENT MANAGEMENT",
  //     title: "Event Ticket",
  //     path: Routes.EventTicketOverview.path,
  //     auth: "admin.event.ticket:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.event.ticket:view"))
  //   },
  //   {
  //     category: "EVENTS CONTENT MANAGEMENT",
  //     title: "Category Selection Events",
  //     path: Routes.CategorySelectionEvents.path,
  //     auth: "admin.event.category:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.event.category:view"))
  //   },
  //   {
  //     category: "GROUPS CONTENT MANAGEMENT",
  //     title: "Groups",
  //     path: Routes.GroupListOverview.path,
  //     auth: "admin.group:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.group:view"))
  //   },
  //   // {
  //   //   category: "GROUPS CONTENT MANAGEMENT",
  //   //   title: "Reported Groups",
  //   //   path: Routes.ReportedGroupListOverview.path,
  //   //   auth: "admin.reported.group:view",
  //   // hidden: (!/user?.email === "superadmin@youapp.ai" && autorizes?.includes("admin.reported.group:view"))
  //   // },
  //   {
  //     category: "GROUPS CONTENT MANAGEMENT",
  //     title: "Featured Groups",
  //     path: Routes.FeatureGroupOverview.path,
  //     auth: "admin.featured.group:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.featured.group:view"))
  //   },
  //   {
  //     category: "PLACES CONTENT MANAGEMENT",
  //     title: "Featured Places",
  //     path: Routes.FeaturePlaceOverview.path,
  //     auth: "admin.featured.places:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.featured.places:view"))
  //   },
  //   {
  //     category: "PLACES CONTENT MANAGEMENT",
  //     title: "Places",
  //     path: Routes.PlacesOverview.path,
  //     auth: "admin.place:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.place:view"))
  //   },
  //   // {
  //   //   category: "PLACES CONTENT MANAGEMENT",
  //   //   title: "Reported Places",
  //   //   path: Routes.ReportedPlacesOverview.path,
  //   //   auth: "admin.reported.place:view",
  //   // hidden: (!/user?.email === "superadmin@youapp.ai" && autorizes?.includes("admin.reported.place:view"))
  //   // },
  //   {
  //     category: "PLACES CONTENT MANAGEMENT",
  //     title: "Category Selection Places",
  //     path: Routes.CategorySelectionPlace.path,
  //     auth: "admin.category.selection.places:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.category.selection.places:view"))
  //   },
  //   {
  //     category: "COMUNITIES CONTENT MANAGEMENT",
  //     title: "Featured Comunities",
  //     path: Routes.FeatureComunityOverview.path,
  //     auth: "admin.featured.community:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.featured.community:view"))
  //   },
  //   {
  //     category: "COMUNITIES CONTENT MANAGEMENT",
  //     title: "Communities",
  //     path: Routes.ComunityOverview.path,
  //     auth: "admin.community:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.community:view"))
  //   },
  //   // {
  //   //   category: "COMUNITIES CONTENT MANAGEMENT",
  //   //   title: "Reported Communities",
  //   //   path: Routes.ReportedComunityOverview.path,
  //   //   auth: "admin.reported.community:view",
  //   // hidden: (!/user?.email === "superadmin@youapp.ai" && autorizes?.includes("admin.reported.community:view"))
  //   // },
  //   {
  //     category: "COMUNITIES CONTENT MANAGEMENT",
  //     title: "Articles Category",
  //     path: Routes.ArticleCategorie.path,
  //     auth: "admin.article.category:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.article.category:view"))
  //   },
  //   {
  //     category: "COMUNITIES CONTENT MANAGEMENT",
  //     title: "Content of All Communities",
  //     path: Routes.ComunityContent.path,
  //     auth: "admin.community.contents:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.community.contents:view"))
  //   },
  //   // {
  //   //   category: "COMUNITIES CONTENT MANAGEMENT",
  //   //   title: "Articles of Communities",
  //   //   path: Routes.ArticlesOverview.path,
  //   //   auth: "admin.article:view",
  //   // hidden: (!/user?.email === "superadmin@youapp.ai" && autorizes?.includes("admin.article:view"))
  //   // },
  //   // {
  //   //   category: "COMUNITIES CONTENT MANAGEMENT",
  //   //   title: "Reported Articles of Communities",
  //   //   path: Routes.ReportedArticlesOverview.path,
  //   //   auth: "admin.reported.community:view",
  //   // hidden: (!/user?.email === "superadmin@youapp.ai" && autorizes?.includes("admin.reported.community:view"))
  //   // },
  //   {
  //     category: "WISHLIST CONTENT MANAGEMENT",
  //     title: "Wishlist of User",
  //     path: Routes.WishlistUser.path,
  //     auth: "admin.wishlist.user:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.wishlist.user:view"))
  //   },
  //   {
  //     category: "WISHLIST CONTENT MANAGEMENT",
  //     title: "Experience Wishlists",
  //     path: Routes.WishlistService.path,
  //     auth: "admin.wishlist.exp:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.wishlist.exp:view"))
  //   },
  //   {
  //     category: "WISHLIST CONTENT MANAGEMENT",
  //     title: "Event Wishlists",
  //     path: Routes.WishlistEvent.path,
  //     auth: "admin.wishlist.event:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.wishlist.event:view"))
  //   },
  //   {
  //     category: "WISHLIST CONTENT MANAGEMENT",
  //     title: "Paid Groups Wishlists",
  //     path: Routes.WishlistGroup.path,
  //     auth: "admin.wishlist.group:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.wishlist.group:view"))
  //   },
  //   {
  //     category: "WISHLIST CONTENT MANAGEMENT",
  //     title: "Places Wishlists",
  //     path: Routes.WishlistPlace.path,
  //     auth: "admin.wishlist.place:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.wishlist.place:view"))
  //   },
  //   {
  //     category: "WISHLIST CONTENT MANAGEMENT",
  //     title: "Bought Wishlists",
  //     path: Routes.BoughtWishlist.path,
  //     auth: "admin.wishlist.bought:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.wishlist.bought:view"))
  //   },
  //   {
  //     category: "FINANCE MANAGEMENT",
  //     title: "Payment Request",
  //     path: Routes.PaymentRequest.path,
  //     auth: "admin.payment.request:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.payment.request:view"))
  //   },
  //   {
  //     category: "FINANCE MANAGEMENT",
  //     title: "YOU + Subscribed User",
  //     path: Routes.YouPlusOverview.path,
  //     auth: "admin.subscription.user:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.subscription.user:view"))
  //   },
  //   {
  //     category: "FINANCE MANAGEMENT",
  //     title: "Withdrawal Request",
  //     path: Routes.WithdrawalRequest.path,
  //     auth: "admin.withdrawal.request:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.withdrawal.request:view"))
  //   },
  //   {
  //     category: "FINANCE MANAGEMENT",
  //     title: "Transaction History",
  //     path: Routes.TransactionHistory.path,
  //     auth: "admin.transaction:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.transaction:view"))
  //   },
  //   {
  //     category: "FINANCE MANAGEMENT",
  //     title: "Debit Credit Assets",
  //     path: Routes.DebitOverview.path,
  //     auth: "admin.debit.credit.assets:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.debit.credit.assets:view"))
  //   },
  //   {
  //     category: "FINANCE MANAGEMENT",
  //     title: "Live Users Asset Holding",
  //     path: Routes.LiveUserOverview.path,
  //     auth: "admin.asset.holding.live:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.asset.holding.live:view"))
  //   },
  //   {
  //     category: "FINANCE MANAGEMENT",
  //     title: "Snapshot of Daily Assets Holding",
  //     path: Routes.SnapshootOverview.path,
  //     auth: "admin.asset.holding.daily:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.asset.holding.daily:view"))
  //   },
  //   {
  //     category: "FINANCE MANAGEMENT",
  //     title: "View all Fees Earned",
  //     path: Routes.ViewAllFeesOverview.path,
  //     auth: "admin.youapp.fees.earnings.all:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.youapp.fees.earnings.all:view"))
  //   },
  //   {
  //     category: "FINANCE MANAGEMENT",
  //     title: "Total Fees Earned Per Day",
  //     path: Routes.TotalFeesDayOverview.path,
  //     auth: "admin.youapp.fees.earnings.daily:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.youapp.fees.earnings.daily:view"))
  //   },
  //   {
  //     category: "FINANCE MANAGEMENT",
  //     title: "Locked Assets",
  //     path: Routes.LockedAssetsOverview.path,
  //     auth: "admin.loacked.assets:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.loacked.assets:view"))
  //   },

  //   {
  //     category: "SELF DISCOVERY",
  //     title: "Daily Zodiac",
  //     path: Routes.ZodiacDailyOverview.path,
  //     auth: "admin.horoscope.daily:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.horoscope.daily:view"))
  //   },
  //   {
  //     category: "SELF DISCOVERY",
  //     title: "Daily Horoscope",
  //     path: Routes.HoroscopeDailyOverview.path,
  //     auth: "admin.horoscope.daily:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.horoscope.daily:view"))
  //   },
  //   {
  //     category: "SELF DISCOVERY",
  //     title: "Horoscope Titles",
  //     path: Routes.HoroscopeTitleOverview.path,
  //     auth: "admin.horoscope.daily.title:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.horoscope.daily.title:view"))
  //   },
  //   {
  //     category: "SELF DISCOVERY",
  //     title: "Ba Zi",
  //     path: Routes.BaziWeightOverview.path,
  //     auth: "admin.bazi.bone:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.bazi.bone:view"))
  //   },
  //   {
  //     category: "SELF DISCOVERY",
  //     title: "Ba Zi Titles",
  //     path: Routes.BaziTitlesOverview.path,
  //     auth: "admin.bazi.bone.title:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.bazi.bone.title:view"))
  //   },
  //   {
  //     category: "SELF DISCOVERY",
  //     title: "Lucky Categories",
  //     path: Routes.LuckyCategoriesOverview.path,
  //     auth: "admin.luck.category:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.luck.category:view"))
  //   },
  //   {
  //     category: "SELF DISCOVERY",
  //     title: "Lucky Items",
  //     path: Routes.LuckyItemsOverview.path,
  //     auth: "admin.luck.item:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.luck.item:view"))
  //   },

  //   {
  //     category: "MASTER ADMIN",
  //     title: "Role",
  //     path: Routes.RoleOverview.path,
  //     auth: "role:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("role:view"))
  //   },
  //   {
  //     category: "MASTER ADMIN",
  //     title: "User Admin",
  //     path: Routes.UserOverview.path,
  //     auth: "admin:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin:view"))
  //   },
  //   // Newsletter
  //   {
  //     category: "NEWSLETTER",
  //     title: "Subscriber List",
  //     path: Routes.NewsLetterOverview.path,
  //     auth: "admin.exchange:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.exchange:view"))
  //   },
  //   {
  //     category: "NEWSLETTER",
  //     title: "Sent Log",
  //     path: Routes.NewsLetterSentLogOverview.path,
  //     auth: "admin.exchange:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.exchange:view"))
  //   },
  //   {
  //     category: "NEWSLETTER",
  //     title: "Daily Newsletter Settings",
  //     path: Routes.NewsletterSetting.path,
  //     auth: "admin.exchange:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.exchange:view"))
  //   },
  //   // Global Settings
  //   {
  //     category: "GLOBAL SETTINGS",
  //     title: "All Exchange Rates",
  //     path: Routes.exchangeRate.path,
  //     auth: "admin.exchange:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.exchange:view"))
  //   },
  //   {
  //     category: "GLOBAL SETTINGS",
  //     title: "Daily FX Rates Snapshot",
  //     path: Routes.DailyFxRate.path,
  //     auth: "admin.rates.snapshoot:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.rates.snapshoot:view"))
  //   },
  //   {
  //     category: "GLOBAL SETTINGS",
  //     title: "Subscription Settings",
  //     path: Routes.subcription.path,
  //     auth: "admin.subscription.setting:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.subscription.setting:view"))
  //   },
  //   {
  //     category: "GLOBAL SETTINGS",
  //     title: "Global Settings",
  //     path: Routes.globalSettings.path,
  //     auth: "admin.fee.setting:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.fee.setting:view"))
  //   },
  //   {
  //     category: "GLOBAL SETTINGS",
  //     title: "Versioning Settings",
  //     path: Routes.versioningSettings.path,
  //     auth: "admin.version.setting:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.version.setting:view"))
  //   },
  //   {
  //     category: "GLOBAL SETTINGS",
  //     title: "Keywords Settings",
  //     path: Routes.keywordSettings.path,
  //     auth: "keywords.setting:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("keywords.setting:view"))
  //   },
  //   {
  //     category: "GLOBAL SETTINGS",
  //     title: "Exemption Fees",
  //     path: Routes.profileExemptionBuyerFees.path,
  //     auth: "admin.skip.fee:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.skip.fee:view"))
  //   },
  //   {
  //     category: "GLOBAL SETTINGS",
  //     title: "Offensive Words Settings",
  //     path: Routes.offensiveWords.path,
  //     auth: "admin.offensive.words:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.offensive.words:view"))
  //   },
  //   {
  //     category: "GLOBAL SETTINGS",
  //     title: "Announcement (Push Notification)",
  //     path: Routes.announcementoverview.path,
  //     auth: "admin.anncouncement:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.anncouncement:view"))
  //   },
  //   {
  //     category: "GLOBAL SETTINGS",
  //     title: "Announcement (Alert Notification)",
  //     path: Routes.announcementAlertOverview.path,
  //     auth: "admin.anncouncement:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.anncouncement:view"))
  //   },
  //   {
  //     category: "GLOBAL SETTINGS",
  //     title: "In App Banner",
  //     path: Routes.banneroverview.path,
  //     auth: "admin.appbanner:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.appbanner:view"))
  //   },
  //   {
  //     category: "GLOBAL SETTINGS",
  //     title: "Promo Code",
  //     path: Routes.PromoCodeOverview.path,
  //     auth: "admin.promo.code:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.promo.code:view"))
  //   },
  //   {
  //     category: "GLOBAL SETTINGS",
  //     title: "Matching Setting",
  //     path: Routes.MatchingSetting.path,
  //     auth: "admin.promo.code:view",
  //     sidebar: true,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.promo.code:view"))
  //   },
  //   {
  //     category: "LOGS",
  //     title: "Log History",
  //     path: Routes.logHistory.path,
  //     sidebar: true,
  //     auth: 'logs:view',
  //     hidden: (!isSuperAdmin || autorizes?.includes("logs:view"))
  //   },
  //   // Detail Routes
  //   {
  //     category: "USER MANAGEMENT",
  //     path: Routes.AddUserOverview.path,
  //     sidebar: false,
  //     auth: "admin:view",
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin:view"))
  //   },
  //   {
  //     category: "USER MANAGEMENT",
  //     path: Routes.EditRoleOverview.path,
  //     sidebar: false,
  //     auth: "role:view",
  //     hidden: (!isSuperAdmin || autorizes?.includes("role:view"))
  //   },
  //   {
  //     category: "USER MANAGEMENT",
  //     path: Routes.EditProfileOverview.path,
  //     sidebar: false,
  //     auth: "profile:action",
  //     hidden: (!isSuperAdmin || autorizes?.includes("profile:action"))
  //   },
  //   {
  //     category: "USER MANAGEMENT",
  //     path: Routes.DetailProfileChangeRequestOverview.path,
  //     sidebar: false,
  //     auth: "request.birthdate:view",
  //     hidden: (!isSuperAdmin || autorizes?.includes("request.birthdate:view"))
  //   },
  //   {
  //     category: "USER MANAGEMENT",
  //     path: Routes.DetailIdentityVerificationOverview.path,
  //     sidebar: false,
  //     auth: "admin.identity:view",
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.identity:view"))
  //   },
  //   {
  //     category: "USER MANAGEMENT",
  //     path: Routes.updateIdentityVerificationOverview.path,
  //     auth: "admin.identity:edit",
  //     sidebar: false,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.identity:edit"))
  //   },
  //   {
  //     category: "USER MANAGEMENT",
  //     path: Routes.KYCDetail.path,
  //     auth: "kyc:view",
  //     sidebar: false,
  //     hidden: (!isSuperAdmin || autorizes?.includes("kyc:view"))
  //   },
  //   {
  //     category: "USER MANAGEMENT",
  //     path: Routes.DetailProfileTag.path,
  //     auth: "profile:tag",
  //     sidebar: false,
  //     hidden: (!isSuperAdmin || autorizes?.includes("profile:tag"))
  //   },
  //   {
  //     category: "USER MANAGEMENT",
  //     path: Routes.EditProfileTag.path,
  //     auth: "profile:tag",
  //     sidebar: false,
  //     hidden: (!isSuperAdmin || autorizes?.includes("profile:tag"))
  //   },
  //   {
  //     category: "USER MANAGEMENT",
  //     path: Routes.DetailCategorySelectionYouHost.path,
  //     auth: "admin.youhost.category:view",
  //     sidebar: false,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.youhost.category:view"))
  //   },
  //   {
  //     category: "USER MANAGEMENT",
  //     path: Routes.EditCategorySelectionYouHost.path,
  //     auth: "admin.youhost.category:view",
  //     sidebar: false,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.youhost.category:view"))
  //   },
  //   {
  //     category: "USER MANAGEMENT",
  //     path: Routes.CreateCategorySelectionYouHost.path,
  //     auth: "admin.youhost.category:view",
  //     sidebar: false,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.youhost.category:view"))
  //   },
  //   {
  //     category: "USER MANAGEMENT",
  //     path: Routes.CreateProfileTag.path,
  //     auth: "profile:tag",
  //     sidebar: false,
  //     hidden: (!isSuperAdmin || autorizes?.includes("profile:tag"))
  //   },
  //   {
  //     category: "SERVICES CONTENT MANAGEMENT",
  //     path: Routes.DetailProductServicesOverview.path,
  //     auth: "service:view",
  //     sidebar: false,
  //     hidden: (!isSuperAdmin || autorizes?.includes("service:view"))
  //   },
  //   {
  //     category: "SERVICES CONTENT MANAGEMENT",
  //     path: Routes.EditProductServicesOverview.path,
  //     auth: "service:view",
  //     sidebar: false,
  //     hidden: (!isSuperAdmin || autorizes?.includes("service:view"))
  //   },
  //   {
  //     category: "SERVICES CONTENT MANAGEMENT",
  //     path: Routes.AddFeatureServiceOverview.path,
  //     auth: "admin.featured.services:view",
  //     sidebar: false,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.featured.services:view"))
  //   },
  //   {
  //     category: "SERVICES CONTENT MANAGEMENT",
  //     path: Routes.CreateDiscoverOverview.path,
  //     auth: "admin.discover:view",
  //     sidebar: false,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.discover:view"))
  //   },
  //   {
  //     category: "SERVICES CONTENT MANAGEMENT",
  //     path: Routes.DetailDiscoverOverview.path,
  //     auth: "admin.discover:view",
  //     sidebar: false,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.discover:view"))
  //   },
  //   {
  //     category: "SERVICES CONTENT MANAGEMENT",
  //     path: Routes.EditDiscoverOverview.path,
  //     auth: "admin.discover:view",
  //     sidebar: false,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.discover:view"))
  //   },
  //   {
  //     category: "SERVICES CONTENT MANAGEMENT",
  //     path: Routes.DetailViewSubmissionsOverview.path,
  //     auth: "admin.discover:view",
  //     sidebar: false,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.discover:view"))
  //   },
  //   {
  //     category: "SERVICES CONTENT MANAGEMENT",
  //     path: Routes.AddFeatureDiscoverExpOverview.path,
  //     auth: "admin.featured.services:view",
  //     sidebar: false,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.featured.services:view"))
  //   },
  //   {
  //     category: "SERVICES CONTENT MANAGEMENT",
  //     title: "Category Selection Experience",
  //     path: Routes.DetailCategorySelectionExperience.path,
  //     auth: "admin.product.category:view",
  //     sidebar: false,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.product.category:view"))
  //   },
  //   {
  //     category: "SERVICES CONTENT MANAGEMENT",
  //     title: "Category Selection Experience",
  //     path: Routes.EditCategorySelectionExperience.path,
  //     auth: "admin.product.category:view",
  //     sidebar: false,
  //     hidden: (!isSuperAdmin || autorizes?.includes("admin.product.category:view"))
  //   },
  // ]
};
