import { createContext, useState, useContext, useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import createApiInstance from "../services/api";
import { MenuPage } from "constants/menu_pages";
import { UnrestrictedRoute } from "routes";
import { LocalCookies } from "features/global/data/localCookies";
import { routeMap } from "refactor_routes";

const AuthContext = createContext({
  user: null,
  authenticate: (newToken) => Promise(),
  isLoading: false,
  isAuthenticated: false,
  autorizes: [],
  isSuperAdmin: false,
  token: "",
  refresh: () => { }
});

export const AuthProvider = ({ children }) => {
  const history = useNavigate();
  const api = createApiInstance();

  const [user, setUser] = useState(null);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [autorizes, setAutorizes] = useState([]);


  useEffect(() => {
    async function loadUserFromCookies() {
      const token = Cookies?.get("token");
      const user = Cookies?.get("meta");
      // const authorize1 = Cookies?.get("authorize_1")
      // const authorize2 = Cookies?.get("authorize_2")
      // const authorize3 = Cookies?.get("authorize_3")
      // const authorize11 = authorize1 !== undefined ? JSON.parse(authorize1) : []
      // const authorize22 = authorize2 !== undefined ? JSON.parse(authorize2) : []
      // const authorize33 = authorize3 !== undefined ? JSON.parse(authorize3) : []
      // const autorize = [...authorize11, ...authorize22, ...authorize33];

      const autorize = Object.keys(Cookies.get())
        .filter((cookieName) => cookieName.startsWith("authorize_"))
        .map((cookieName) => {
          const cookieValue = Cookies.get(cookieName);
          try {
            return JSON.parse(cookieValue);
          } catch (e) { return [] }
        }).reduce((acc, curr) => acc.concat(curr), []);

      if (token && user) {
        api.defaults.headers.Authorization = `Bearer ${token}`;
        setUser(JSON.parse(user));
        if (autorize?.length > 0) setAutorizes(autorize);
      }
      setLoading(false);
    }
    loadUserFromCookies();
  }, []);

  const login = async (username, password) => {
    setLoading(true);

    const { data, status } = await api.post(`/auth/login`, { email: username, password: password, });
    const { accessToken: token, autorize, ...props } = data.data;

    if (status && token) {
      setCookieHelper({ meta: props, autorize, userData: data?.data })
      // sessionStorage.setItem("user", JSON.stringify(props));
      // Cookies.set("meta", JSON.stringify(props));
      // Cookies.set("autorize", JSON.stringify(autorize));
      // setAutorizes(autorize);
      // setUser(data.data);
      // setIsSuperAdmin(props?.email !== "superadmin@youapp.ai")

      Cookies.set("token", token, { expires: 60 });
      api.defaults.headers.Authorization = `Bearer ${token}`;

      LocalCookies.filterCountry.set(data.data?.filterCountry)
      LocalCookies.filterCountry.setDefault(data.data?.filterCountry)
      // console.log(`country ${LocalCookies.filterCountry.get()}`)
    }

    return data;
  };

  const logout = async () => {
    try {
      await api.post(`/auth/logout`, { token: Cookies.get("token"), });
      Object.keys(Cookies.get()).forEach((cookieName) => Cookies.remove(cookieName.trim()))

      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token");
      Cookies.remove("user");
      Cookies.remove("token");
      LocalCookies.filterCountry.delete();
      LocalCookies.filterCountry.deleteDefault();
      setUser(null);
      setAutorizes([]);
      history("/login");
    } catch (error) {
      console.log("failed logout");
      console.warn(error);
    }
  };


  const clearTokenOnUnload = () => {
    // Hapus token dari Cookies saat tab ditutup
    sessionStorage.removeItem("token");
  };

  const refresh = async () => {
    try {
      const { data, status } = await api.get(`/auth/authorize`);
      const { autorize, ...props } = data.data;

      if (status) {
        setCookieHelper({ meta: props, autorize, userData: data?.data })
        // sessionStorage.setItem("user", JSON.stringify(props));
        // Cookies.set("meta", JSON.stringify(props));
        // Cookies.set("autorize", JSON.stringify(autorize));
        // setAutorizes(autorize);
        // setUser(data.data);
        // setIsSuperAdmin(props?.email !== "superadmin@youapp.ai")
        LocalCookies.filterCountry.setDefault(data.data?.filterCountry)
      }
    } catch (error) {
      console.log('failed to refresh : ', error);
      console.warn('failed to refresh : ', error);
    }
  }

  const setCookieHelper = ({ meta, autorize, userData }) => {
    setUser(userData);
    if (meta) {
      sessionStorage.setItem("user", JSON.stringify(meta));
      setIsSuperAdmin(meta?.email !== "superadmin@youapp.ai")
      Cookies.set("meta", JSON.stringify(meta));
    }
    if (autorize) {
      setAutorizes(autorize);
      // Cookies.set("autorize", JSON.stringify(autorize));
      // Cookies.set("authorize_1", JSON.stringify(autorize.slice(0, 70)));
      // Cookies.set("authorize_2", JSON.stringify(autorize.slice(70, 140)));
      // Cookies.set("authorize_3", JSON.stringify(autorize.slice(140, 200)));
      setAuthorizeCookies(autorize)
    }
  }

  const setAuthorizeCookies = (autorize, chunkSize = 65) => {
    Object.keys(Cookies.get()).forEach((cookieName) => {
      if (cookieName.startsWith("authorize_")) {
        Cookies.remove(cookieName);
      }
    });

    for (let i = 0; i < autorize.length; i += chunkSize) {
      const chunk = autorize.slice(i, i + chunkSize);
      const cookieName = `authorize_${Math.floor(i / chunkSize) + 1}`;
      Cookies.set(cookieName, JSON.stringify(chunk), { path: "/" });
    }
  };


  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        login,
        logout,
        isLoading,
        autorizes,
        isSuperAdmin,
        refresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};


export const useAuth = () => useContext(AuthContext);

export const ProtectRoute = ({ children }) => {
  const history = useNavigate();
  const api = createApiInstance();
  const token = Cookies.get("token");
  const { isLoading, user, autorizes, refresh } = useAuth();
  const location = useLocation();

  const isAccessible = () => {
    const unrestrictedPage = [...Object.values(UnrestrictedRoute)].map((value) => value.path)
    if (unrestrictedPage.includes(location.pathname)) return true
    const pageList = MenuPage(autorizes, user?.email ?? null).reduce((prev, curr, index, arr) => {
      if (curr?.auth?.value)
        if (autorizes?.includes(curr?.auth?.value ?? '')) {
          prev.push(curr)
        }
      return prev;
    }, [])
    if (pageList.map((value) => value.path).includes(location.pathname)) return true
    if (!MenuPage(autorizes, user?.email ?? null).map((value) => value.path).includes(location.pathname)) return true

    return false
  }

  useEffect(() => {
    if (!isLoading) {
      if (token && user) {
        api.defaults.headers.Authorization = `Bearer ${token}`;
        if (location.pathname === '/' && autorizes?.includes("dashboard:view")) {
          history(routeMap().DashboardOverview.path)
        }
        if (!isAccessible()) {
          history(routeMap().AccessDenied.path)
        }
        if (location.pathname === '/login') {
          history(routeMap().DashboardOverview.path)
        }
        refresh()
      } else if (location.pathname !== '/login' && typeof window !== "undefined") {

        history("/login");
      }
    }
  }, [isLoading, token, history, location.pathname])

  return children;
};
